import $ from 'jquery';
import bootbox from 'bootbox';
import 'jquery-validation';
import { serialize } from 'object-to-formdata';

import config from './config';
import { postApi } from './api-manager';
import { getClientId } from './auth-manager';
import { renderBasicPopup, renderBasicErrorPopup } from './common';
import { personnelManager } from './personnel-manager';

export function showCreateTicketModal() {
    const message = createTicketMessage();
    bootbox.confirm({
        animate: false,
        title: 'CONTACT LOCUS',
        message,
        buttons: {
            confirm: {
                label: 'Send',
                className: 'create-ticket-btn btn-primary',
            },
            cancel: {
                label: 'Cancel',
                className: 'btn-primary',
            },
        },
        callback(result) {
            if (!result) {
                return;
            }
            $('form.create-ticket').validate();
            if ($('form.create-ticket').valid()) {
                $('.create-ticket-btn').attr('disabled', 'true');
                createTicketRequest();
            }
            return false;
        },
    });
}

function createTicketMessage() {
    const personnel = personnelManager.createSession();
    const personnelName = personnel.getName();
    const personnelEmail = personnel.getEmail();

    const message =
        `<form class='create-ticket'>` +
        `<div class='row'><div class='col-md-12'>NAME</div></div>` +
        `<div class='row'>` +
        `<div class='col-sm-12'>` +
        `<input class='requester_name' value='${personnelName}' name='requester_name' type='text' ` +
        `placeholder='Enter Your Name' required/>` +
        `</div>` +
        `</div>` +
        `<br>` +
        `<div class='row'><div class='col-md-12'>E-MAIL ID</div></div>` +
        `<div class='row'>` +
        `<div class='col-sm-12'>` +
        `<input class='requester_email' value='${personnelEmail}'  name='requester_email' type='text' ` +
        `placeholder='Enter Your E-mail ID' required/>` +
        `</div>` +
        `</div>` +
        `<br>` +
        `<div class='row'>` +
        `<div class='col-md-12'>CC <span class='optional'>(optional)</span></div>` +
        `</div>` +
        `<div class='row'>` +
        `<div class='col-sm-12'>` +
        `<input class='cc' type='text' placeholder='Enter comma seperated E-mail IDs' />` +
        `</div>` +
        `</div>` +
        `<br>` +
        `<div class='row'>` +
        `<div class='col-md-12'>CONTACT NUMBER <span class='optional'>(optional)</span></div>` +
        `</div>` +
        `<div class='row'>` +
        `<div class='col-sm-12'>` +
        `<input class='contact_number' type='text' placeholder='Enter Contact Number' />` +
        `</div>` +
        `</div>` +
        `<br>` +
        `<div class='row'> ` +
        `<div class='col-sm-12'> SUBJECT</div>` +
        `</div>` +
        `<div class='row'>` +
        `<div class='col-sm-12'>` +
        `<input class='subject' type='text' name='subject' ` +
        `placeholder='Mention a few words about your issue' required />` +
        `</div>` +
        `</div>` +
        `<br>` +
        `<div class='row'>` +
        `<div class='col-md-12'> URL  <span class='optional'>(optional)</span></div>` +
        `</div>` +
        `<div class='row'>` +
        `<div class='col-sm-12'>` +
        `<input class='url' type='text' placeholder='Enter URL' />` +
        `</div>` +
        `</div>` +
        `<br>` +
        `<div class='row'>` +
        `<div class='col-sm-12'> MESSAGE</div>` +
        `</div>` +
        `<div class='row'>` +
        `<div class='col-sm-12'>` +
        `<textarea class='message' name='message' type='text' ` +
        `placeholder='Please explain the issue in detail here' required />` +
        `</div>` +
        `</div>` +
        `<br>` +
        `<div class='row'> ` +
        `<div class='col-md-12'>ATTACHMENTS <span class='optional'>(if any)</span></div>` +
        `</div>` +
        `<div class='attachments'>` +
        `<label class="file-upload">` +
        `<input type='file' multiple name='file' id='uploadFiles' > <img src="images/upload.svg"> Custom Upload ` +
        `</label>` +
        `</div>` +
        `</form>`;
    return message;
}

async function createTicketRequest() {
    const requestJSON = {};

    requestJSON.name = $('.requester_name').val();
    requestJSON.email = $('.requester_email').val();
    requestJSON.priority = 1;
    requestJSON.status = 2;
    requestJSON.subject = $('.subject').val();
    requestJSON.description = fetchTicketDescription();

    const collaboratorsString = $('.cc').val().trim();
    if (collaboratorsString !== '') {
        const collaborators = collaboratorsString.value
            ? collaboratorsString.value.split(',').length > 0
                ? collaboratorsString.value.split(',')
                : []
            : [];
        requestJSON.cc_emails = collaborators;
    }

    // eslint-disable-next-line prefer-destructuring
    const { files } = $('#uploadFiles')[0];
    if (files.length) {
        requestJSON.attachments = Array.from(files);
    }

    const { baseUrl, apiKey } = config.freshdesk;

    const requestBody = requestJSON.attachments
        ? serialize(requestJSON)
        : requestJSON;

    postApi(`${baseUrl}/api/v2/tickets/`, requestBody, true, {
        Authorization: `Basic ${btoa(`${apiKey}:x`)}`,
    })
        .done((data) => {
            renderSuccessfulCreationModal(data);
        })
        .fail((err) => {
            bootbox.hideAll();
            renderBasicErrorPopup(err);
        });
}

function fetchTicketDescription() {
    const clientId = getClientId();
    let description = `${$('.message').val()}\n`;
    const contactNumber = $('.contact_number').val().trim();
    const url = $('.url').val().trim();

    description += `Current URL: ${window.location.origin} \n`;

    if (url !== '') {
        description += `User URL: ${url}\n`;
    }

    if (contactNumber !== '') {
        description += `Phone: ${contactNumber} \n`;
    }

    description += `Client ID: ${clientId} \n`;

    return description;
}

function renderSuccessfulCreationModal(data) {
    const requestId = data.id;
    bootbox.hideAll();
    const message =
        `<div class='create-ticket_success' >` +
        `<div> Your Request (#${requestId}) has been submitted. </div>` +
        `<div> You will receive an e-mail with details for further tracking</div>` +
        `</div>`;
    renderBasicPopup('SUCCESS', message);
}
