export const personnelManager = (function () {
    var instance;
    function init(personnel) {
        return {
            getName() {
                return personnel ? personnel.name : '';
            },
            getEmail() {
                return personnel ? personnel.email.emailAddress : '';
            },
            getDesignation() {
                return personnel
                    ? personnel.customProperties &&
                          personnel.customProperties.designation
                    : '';
            },
            hasOperationPermission(operation) {
                if (
                    operation &&
                    personnel &&
                    personnel.roleMaster &&
                    personnel.roleMaster.allowedOperations &&
                    personnel.roleMaster.allowedOperations.includes(operation)
                ) {
                    return true;
                }

                return false;
            },
        };
    }

    return {
        createSession(personnel) {
            if (!instance) {
                instance = init(personnel);
            }
            return instance;
        },
    };
})();
