import { getApi, postApi } from './api-manager';
import { extractClientId, platformBaseUrl, renderBasicPopup } from './common';
import Cookies from 'js-cookie';
import * as auth0 from './auth0';

var sessionManager = (function () {
    var instance;
    function init() {
        var clientId = extractClientId();

        if (!clientId) {
            renderBasicPopup('ERROR', 'Invalid Client Id in the url.');
            return;
        }

        return {
            getClientId() {
                return clientId;
            },
            getUsername() {
                return Cookies.get('username');
            },
            getPassword() {
                return Cookies.get('password');
            },
            getToken() {
                return Cookies.get('token');
            },
            clear() {
                Cookies.remove('username');
                Cookies.remove('password');
                Cookies.remove('token');
            },
        };
    }

    return {
        createSession() {
            if (!instance) {
                instance = init();
            }
            return instance;
        },
    };
})();

export function login({ username, password }) {
    Cookies.set('username', username);
    Cookies.set('password', password);

    fetchPersonnel()
        .done(() => {
            const clientId = getClientId();
            window.location.href = `index.html?clientId=${clientId}`;
        })
        .fail(() => {
            renderBasicPopup('ERROR', 'Login Failed. Please try again');
        });
}

export function loginSSO({ connection }) {
    const clientId = getClientId();
    auth0.login({ clientId, connection });
}

export function invalidateToken() {
    var url = `${platformBaseUrl}/logout`;
    return postApi(url, {}, false);
}

export function logout(returnTo) {
    invalidateToken();
    sessionManager.createSession().clear();
    var clientId = getClientId();
    auth0.logout(
        clientId,
        returnTo || `${window.location.origin}/?clientId=${clientId}`,
    );
}

export function isAuthenticated() {
    return Boolean(getUsername());
}

export function getClientId() {
    return sessionManager.createSession().getClientId();
}

export function getUsername() {
    return sessionManager.createSession().getUsername();
}

export function fetchPersonnel() {
    var userName = getUsername();
    //Modifying the api url here, as changing username in cookie will create login issue
    userName = userName.replace('personnel', 'personnel-master');
    var url = `${platformBaseUrl}/client/${userName}`;
    const urlParams = {
        include: 'includeRole',
    };
    return getApi(url, urlParams);
}

export function addAuthHeader(xhr) {
    //returning false cancels ajax request
    const header = constructAuthToken();
    if (header === false) {
        logout();
        return false;
    }
    xhr.setRequestHeader('Authorization', header);
}

export function constructAuthToken() {
    const clientManager = sessionManager.createSession();

    // token
    const token = clientManager.getToken();
    if (token) {
        return `Bearer ${token}`;
    }

    if (!isAuthenticated()) return false;

    // basic auth
    const authToken = `${clientManager.getUsername()}:${clientManager.getPassword()}`;
    const encodedToken = btoa(authToken);
    return `Basic ${encodedToken}`;
}

export async function handleAuthRedirect() {
    const clientId = getClientId();
    await auth0.handleRedirect(clientId, logout);
}
