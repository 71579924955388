import $ from 'jquery';
import moment from 'moment';
import 'datatables.net';
import 'datatables.net-dt/css/jquery.dataTables.css';

import defaultSettings from './defaultSettings';
import shiftSettings from './shiftSettings';
import slotSettings from './slotSettings';

import { renderTableView } from './table';

window.$ = window.jQuery = require('jquery');

const TableConfig = {
    defaultSettings,
    shiftSettings,
    slotSettings,
};

export function renderDateTimePickerElements() {
    $('.audit-history-view .start-date').datetimepicker({
        defaultDate: moment(),
        format: 'DD MMM YYYY',
        minDate: moment().subtract(2, 'years'),
        maxDate: moment(),
    });
    $('.audit-history-view .end-date').datetimepicker({
        defaultDate: moment(),
        format: 'DD MMM YYYY',
        minDate: moment().subtract(2, 'years'),
        maxDate: moment(),
    });

    $('.datepicker > div:first-of-type').css('display', 'initial');
}

export function renderAuditHistoryView() {
    const tabSelector = $('.audit-history-view .audit-history-tabs .tab');
    const settingTypeTitleSelector = $(
        '.audit-history-view .settings-type-title',
    );
    const applyButtonSelector = $('.audit-history-view .apply-date-range');

    let selectedTabValue = $(
        '.audit-history-view .audit-history-tabs .tab.selected',
    ).attr('data-value');
    tabSelector.bind('click', function () {
        const clickedElement = $(this);
        if (clickedElement.hasClass('selected')) {
            return;
        }
        tabSelector.removeClass('selected');
        clickedElement.addClass('selected');
        const selectedTabTitle = clickedElement.text();
        selectedTabValue = clickedElement.attr('data-value');
        settingTypeTitleSelector.text(selectedTabTitle);
        renderTableView(TableConfig[selectedTabValue]);
    });

    applyButtonSelector.bind('click', () => {
        const datePickerError = $('.audit-history-view .date-picker-error');
        const startDateSelector = $('.audit-history-view .start-date');
        try {
            const startDate = moment(
                startDateSelector.val(),
                'DD MMM YYYY',
            ).format('YYYY-MM-DD');
            const endDateSelector = $('.audit-history-view .end-date');
            const endDate = moment(endDateSelector.val(), 'DD MMM YYYY').format(
                'YYYY-MM-DD',
            );
            if (moment(startDate).isAfter(endDate, 'day')) {
                datePickerError.text('You have selected invalid date range');
                return;
            }
            datePickerError.text('');
            renderTableView(TableConfig[selectedTabValue]);
        } catch (err) {
            datePickerError.text('You have selected invalid date range');
        }
    });
    renderDateTimePickerElements();
    renderTableView(TableConfig[selectedTabValue]);
}
