import { DEFAULT_SETTINGS } from './constants';

export const DESIGNATIONS = {
    ASI: 'ASI',
    ASM: 'ASM',
    ISP_CCO: 'ISP CCO',
    CSM: 'CSM',
    RH: 'RH',
    ADMIN: 'ADMIN',
};

export const ALLOW_EDIT_SETTINGS_BY_DESIGNATION = {
    DEFAULT_SETTINGS: {
        [DEFAULT_SETTINGS.TECHNICIAN_COUNT]: [
            undefined,
            null,
            DESIGNATIONS.ASI,
            DESIGNATIONS.ASM,
            DESIGNATIONS.CSM,
            DESIGNATIONS.RH,
            DESIGNATIONS.ADMIN,
        ],
        [DEFAULT_SETTINGS.MIN_TECHNICIAN_COUNT]: [
            undefined,
            null,
            DESIGNATIONS.ASM,
            DESIGNATIONS.CSM,
            DESIGNATIONS.RH,
            DESIGNATIONS.ADMIN,
        ],
        [DEFAULT_SETTINGS.SHIFT]: [
            undefined,
            null,
            DESIGNATIONS.ASI,
            DESIGNATIONS.ASM,
            DESIGNATIONS.CSM,
            DESIGNATIONS.RH,
            DESIGNATIONS.ADMIN,
        ],
        [DEFAULT_SETTINGS.SLOT_OPTIONS]: [
            undefined,
            null,
            DESIGNATIONS.ASI,
            DESIGNATIONS.ASM,
            DESIGNATIONS.CSM,
            DESIGNATIONS.RH,
            DESIGNATIONS.ADMIN,
        ],
        [DEFAULT_SETTINGS.TRAVEL_TIME]: [
            undefined,
            null,
            DESIGNATIONS.ASI,
            DESIGNATIONS.ASM,
            DESIGNATIONS.CSM,
            DESIGNATIONS.RH,
            DESIGNATIONS.ADMIN,
        ],
    },
    GENERAL_SETTINGS: {},
};
