import moment from 'moment';
import { SETTING_TYPES } from './constants';

const defaultSettings = {
    type: SETTING_TYPES.SHIFT_SETTINGS,
    columns: [
        {
            title: 'Modified on',
            data: 'updatedOn',
            defaultContent: 'Not Available',
            render: (data) => {
                return moment(data).format('DD MMM YYYY, hh:mm a');
            },
        },
        {
            title: 'Modified by',
            data: 'actor.id',
            orderable: false,
            defaultContent: 'Not Available',
        },
        {
            title: 'Date range',
            data: 'updatedValue',
            orderable: false,
            defaultContent: 'Not Available',
            render: (data) => {
                if (!data || !data.startDate || !data.endDate) {
                    return null;
                }
                const { startDate } = data;
                const { endDate } = data;
                const displayStartDate = moment(startDate).format(
                    'DD MMM YYYY',
                );
                const displayEndDate = moment(endDate).format('DD MMM YYYY');
                return `${displayStartDate} to ${displayEndDate}`;
            },
        },
        {
            title: 'Changed to',
            data: 'updatedValue',
            orderable: false,
            defaultContent: 'Not Available',
            render: (data) => {
                if (!data || !data.shift) {
                    return null;
                }
                const { startTime } = data.shift;
                const { endTime } = data.shift;
                return `${moment(startTime, 'h:mm a').format(
                    'h:mm a',
                )} - ${moment(endTime, 'h:mm a').format('h:mm a')}`;
            },
        },
    ],
    filters: [
        {
            name: 'updatedField',
            operation: 'EQUALS',
            value: 'SHIFT',
            values: [],
            allowEmptyOrNull: false,
            caseSensitive: false,
        },
    ],
};

export default defaultSettings;
