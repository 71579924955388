import $ from 'jquery';
import moment from 'moment';

import { getApi } from './api-manager';
import {
    getMomentFromTime,
    formatDate,
    renderBasicErrorPopup,
    formDateObj,
    baseUrl,
} from './common';
import { settingsManager } from './settings';

export function formWeekView(clientId, teamId, start, end, callback) {
    const settings = settingsManager.getSettings();
    const shiftLimit = settings.getShiftLimit();
    const startDate = moment(start).format('YYYY-MM-DD');
    const endDate = moment(end).format('YYYY-MM-DD');

    const urlParams = {
        startDate,
        endDate,
        teamId,
        useConsistentRead: true,
    };

    const getUserCounts = `${baseUrl}/client/${clientId}/slot-user-count`;

    getApi(getUserCounts, urlParams)
        .done((response) => {
            const { daySlots } = response;
            let { startTime: minShiftTime, endTime: maxShiftTime } = shiftLimit;
            let slotDates = [];
            let events = [];

            if (daySlots.length !== 0) {
                minShiftTime = getMinShiftTime(daySlots);
                maxShiftTime = getMaxShiftTime(daySlots, minShiftTime);

                slotDates = daySlots.map((daySlot) => daySlot.dayId.slotDate);
                events = daySlots.reduce(
                    (result, daySlot) =>
                        result.concat(
                            extractEventsForDay(
                                daySlot,
                                minShiftTime,
                                maxShiftTime,
                            ),
                        ),
                    [],
                );

                minShiftTime = minShiftTime.format('HH:mm');
                maxShiftTime = maxShiftTime.format('HH:mm');
            }

            events = events.concat(
                fetchEmptyEvents(
                    start,
                    end,
                    slotDates,
                    minShiftTime,
                    maxShiftTime,
                ),
            );

            $('#calendar').fullCalendar('option', 'minTime', minShiftTime);
            $('#calendar').fullCalendar('option', 'maxTime', maxShiftTime);

            callback(events);
        })
        .fail((err) => {
            renderBasicErrorPopup(err);
        });
}

function fetchEmptyEvents(
    startDate,
    endDate,
    slotDates,
    minShiftTime,
    maxShiftTime,
) {
    const events = [];

    for (
        let currentDate = moment(startDate);
        currentDate.isBefore(moment(endDate));
        currentDate.add(1, 'days')
    ) {
        const formattedDate = formatDate(currentDate);
        if (slotDates.indexOf(formattedDate) !== -1) {
            continue;
        }
        const start = formDateObj(formattedDate, minShiftTime);
        const end = formDateObj(formattedDate, maxShiftTime);
        events.push(
            createCalendarEvent('empty-event', '', start, end, 'empty-event'),
        );
    }
    return events;
}

function createCalendarEvent(id, title, start, end, className) {
    return {
        id,
        title,
        start,
        end,
        className,
    };
}

function getMinShiftTime(daySlots) {
    const shiftTimes = daySlots.map((daySlot) =>
        getMomentFromTime(daySlot.slots[0].slot.startTime),
    );
    return moment.min(shiftTimes);
}

function getMaxShiftTime(daySlots, minShiftTime) {
    const shiftTimes = daySlots.map((daySlot) =>
        getMomentFromTime(daySlot.slots.slice(-1)[0].slot.endTime),
    );
    let maxShiftTime = moment.max(shiftTimes);
    const settings = settingsManager.getSettings();
    const slotDuration = settings.getSlotWidth() / 60;

    //In fullcalendar, slotDuration takes precedence over events length.
    //Hence add (slotDuration - diff)hr for odd slot duration
    const excessDuration =
        maxShiftTime.diff(minShiftTime, 'hours') % slotDuration;
    if (excessDuration !== 0) {
        maxShiftTime = maxShiftTime.add(slotDuration - excessDuration, 'hour');
    }
    return maxShiftTime;
}

function extractEventsForDay(daySlot, minTime, maxTime) {
    const { slotDate } = daySlot.dayId;
    const { slots } = daySlot;

    const minSlotTime = slots[0].slot.startTime;
    const maxSlotTime = slots.slice(-1)[0].slot.endTime;
    const settings = settingsManager.getSettings();
    const slotDuration = settings.getSlotWidth() / 60;

    let events = nonEmptyEvents(slotDate, slots);
    events = events.concat(
        eventsBeforeMinTime(slotDate, slotDuration, minSlotTime, minTime),
    );
    events = events.concat(
        eventsAfterMaxTime(slotDate, slotDuration, maxSlotTime, maxTime),
    );
    return events;
}

function nonEmptyEvents(slotDate, slots) {
    const events = [];
    slots.forEach((slot) => {
        events.push(event(slotDate, slot));
    });
    return events;
}

function event(slotDate, slot) {
    const id = `${slotDate}_${slot.slotId}`;
    const { startTime, endTime } = slot.slot;
    const start = formDateObj(slotDate, startTime);
    const end = formDateObj(slotDate, endTime);
    let className = 'existing_slot';
    let title = slot.userCount;

    if (!slot.enabled) {
        className = 'add-slot';
        title = '+ Add Slot';
    }
    const event = createCalendarEvent(id, title, start, end, className);
    return event;
}

function eventsBeforeMinTime(slotDate, slotDuration, minSlotTime, minTime) {
    const events = [];
    let slotTime = minSlotTime;

    //Push empty n hour slots until you reach minTime
    while (minSlotTime > minTime) {
        slotTime = minSlotTime;
        minSlotTime = subNHours(minSlotTime, slotDuration);
        const id = 'empty-slot';
        const start = formDateObj(slotDate, minSlotTime);
        const end = formDateObj(slotDate, slotTime);
        events.push(createCalendarEvent(id, '', start, end, id));
    }

    const diffTime = moment(slotTime, 'hh:mm a').diff(
        moment(minTime, 'hh:mm a'),
        'hours',
    );

    //Push empty one hour slot for odd start time
    if (diffTime !== 0) {
        const id = 'empty-slot';
        const start = formDateObj(slotDate, minTime.clone().format('HH:mm'));
        const end = formDateObj(slotDate, slotTime);
        events.push(createCalendarEvent(id, '', start, end, id));
    }
    return events;
}

function eventsAfterMaxTime(slotDate, slotDuration, maxSlotTime, maxTime) {
    const events = [];
    let slotTime = maxSlotTime;
    //Push empty two hour slots until you reach maxTime
    while (slotTime < maxTime) {
        slotTime = addNHours(maxSlotTime, slotDuration);
        const id = 'empty-slot';
        const start = formDateObj(slotDate, maxSlotTime);
        const end = formDateObj(slotDate, slotTime);
        events.push(createCalendarEvent(id, '', start, end, id));
        slotTime = addNHours(slotTime, slotDuration);
        maxSlotTime = slotTime;
    }

    const diffTime = moment(maxTime, 'hh:mm a').diff(
        moment(slotTime, 'hh:mm a'),
        'hours',
    );

    //Push empty one hour slot for odd end time
    if (diffTime !== 0) {
        const id = 'empty-slot';
        const start = formDateObj(slotDate, slotTime);
        const end = formDateObj(slotDate, maxTime.clone().format('HH:mm'));
        events.push(createCalendarEvent(id, '', start, end, id));
    }
    return events;
}

function subNHours(time, duration) {
    return moment.utc(time, 'HH:mm').subtract(duration, 'hour').format('HH:mm');
}

function addNHours(time, duration) {
    return moment.utc(time, 'HH:mm').add(duration, 'hour').format('HH:mm');
}
