export const DEFAULT_SETTINGS = {
    TECHNICIAN_COUNT: 'technician-count',
    MIN_TECHNICIAN_COUNT: 'min-technician-count',
    SHIFT: 'shift',
    SLOT_OPTIONS: 'slot-options',
    TRAVEL_TIME: 'travel-time',
};

export const OperationsEnum = {
    ManageSlots: 'MANAGE_SLOTS',
};
