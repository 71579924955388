import $ from 'jquery';
import 'bootstrap';
import moment from 'moment';
import page from 'page';
import * as qs from 'query-string';

import {
    fetchPersonnel,
    getClientId,
    login,
    loginSSO,
    isAuthenticated,
    handleAuthRedirect,
    logout,
} from './auth-manager';
import {
    changeTechnicianCount,
    discard,
    enableEditing,
    saveAndClose,
    populateCalendarView,
    setTechnicianCountText,
} from './calendar';
import {
    renderBasicPopup,
    extractClientId,
    generateURL,
    insertUrlParam,
    getUrlParams,
} from './common';
import { fetchTeams, getTeamId, showTeamChangeModal } from './team-manager';
import { renderChangeShiftModal, updateSlotEndTime } from './update-shift';
import { personnelManager } from './personnel-manager';
import { renderEditModeData } from './settings-view-manager';
import { showCreateTicketModal } from './ticketing-manager';
import { renderAuditHistoryView } from './audit-history-view/';

import { OperationsEnum } from './constants';

import 'bootstrap/dist/css/bootstrap.css';
import 'fullcalendar/dist/fullcalendar.min.css';
import 'eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.min.css';
import 'X-editable/dist/bootstrap-editable/css/bootstrap-editable.css';
import 'izitoast/dist/css/iziToast.min.css';
import 'select2/dist/css/select2.min.css';

import '../css/login.css';
import '../css/common.css';
import '../css/base-modal.css';
import '../css/calendar.css';
import '../css/settings.css';
import '../css/ticketing-modal.css';
import '../css/audit-history.css';
import '../css/table.css';
import { fetchPlatformConfig, platformConfig } from './platformConfig';

window.$ = window.jQuery = require('jquery');

const pages = {
    calendarView(isFirstRender) {
        if (!isFirstRender) {
            $('.settings-view').hide();
            $('.audit-history-view').hide();
            $('#calendar').fullCalendar('refetchEvents');
            $('.calender-view').show();
            insertUrlParam('view', 'calendarView');
        }
    },
    settingsView() {
        $('.calender-view').hide();
        $('.audit-history-view').hide();
        $('.settings-view').show();
        insertUrlParam('view', 'settingsView');
    },
    auditHistory() {
        $('.calender-view').hide();
        $('.settings-view').hide();
        $('.audit-history-view').show();
        insertUrlParam('view', 'auditHistory');
        renderAuditHistoryView();
    },
};

$(document).ready(() => {
    $('#year').html(moment().year());

    page('/', (e) => {
        if (isAuthenticated()) {
            window.location.href = generateURL('/index.html', e);
            return;
        } else {
            window.location.href = generateURL('/login.html', e);
        }
    });

    page('/login.html', (event) => {
        const clientId = extractClientId();
        const { connection, autologin } = qs.parse(event.querystring);
        const $loginButton = $('.login-sso .btn');

        $loginButton.attr('disabled', true);
        fetchPlatformConfig(clientId)
            .done((config) => {
                platformConfig.updatePlatformConfig(config);
                $loginButton.attr('disabled', false);

                if (connection) {
                    if (autologin) {
                        $loginButton.attr('disabled', true);
                        loginSSO({ connection });
                        return;
                    }

                    return;
                }

                // sso login
                $(document).on('click', '.login-sso .btn', (e) => {
                    $('.login-sso .btn').attr('disabled', true);
                    loginSSO({});
                });

                // Basic-auth login
                $(document).on('submit', '.login-form', () => {
                    let username = $('#username').val();

                    if (username.indexOf('/') === -1) {
                        username = `${clientId}/personnel/${username}`;
                    }

                    login({
                        username,
                        password: $('#password').val(),
                    });

                    return false;
                });
            })
            .fail(() => {
                renderBasicPopup(
                    'ERROR',
                    'Config failed to load. Please try reloading the page',
                );
            });
    });

    page('/login_callback.html', (event) => {
        handleAuthRedirect();
    });

    page('/logout.html', (event) => {
        // only trigger logout if user is authenticated
        const { returnTo } = qs.parse(event.querystring);
        if (!isAuthenticated()) {
            window.location.href = returnTo || generateURL('/login.html');
            return;
        }
        logout(returnTo);
    });

    page('/index.html', async (event) => {
        if (!isAuthenticated()) {
            window.location.href = generateURL('/login.html', event);
            return;
        }

        fetchPersonnel()
            .done((personnel) => {
                const personnelInstance = personnelManager.createSession(
                    personnel,
                );

                const personnelDesignation = personnelInstance.getDesignation();
                const canManageSlot = personnelInstance.hasOperationPermission(
                    OperationsEnum.ManageSlots,
                );

                if (personnel.canManageEntities || canManageSlot) {
                    renderEditModeData(personnelDesignation);
                }

                const clientId = getClientId();

                fetchTeams(clientId, personnel)
                    .done(() => {
                        const teamId = getTeamId();
                        const currentView = getUrlParams().view;
                        if (currentView && pages[currentView]) {
                            const teamId = getTeamId();
                            const clientId = getClientId();
                            populateCalendarView(clientId, teamId);
                            pages[currentView](true);
                        } else {
                            populateCalendarView(clientId, teamId);
                        }
                    })
                    .fail(() => {
                        renderBasicPopup(
                            'ERROR',
                            'Login Failed. Please try again',
                            logout,
                        );
                    });
            })
            .fail(() => {
                renderBasicPopup(
                    'ERROR',
                    'Login Failed. Please try again',
                    logout,
                );
            });
    });

    page.start();

    $(document).on('click', '.help-div', () => {
        showCreateTicketModal();
    });

    $(document).on('click', '.change-shift', () => {
        renderChangeShiftModal();
    });

    $(document).on('click', '.view-settings', () => {
        pages.settingsView();
    });

    $(document).on('click', '.exit-settings', () => {
        event.preventDefault();
        pages.calendarView();
    });

    $(document).on('click', '.view-audit-history', () => {
        event.preventDefault();
        pages.auditHistory();
    });

    $(document).on('click', '.exit-audit-history', () => {
        event.preventDefault();
        pages.settingsView();
    });

    $(document).on('select2:select', '.team-div .team-selector', function () {
        const teamName = $(this).find(':selected').text();
        const teamId = $(this).val();
        showTeamChangeModal(teamId, teamName);
    });

    $(document).on('click', '.technician-number', (event) => {
        const id = getIdFromEvent(event);
        enableEditing(id);
    });

    $(document).on('click', '.addition-edit', (event) => {
        event.preventDefault();
        handleTechnicianCountChange(event, 1);
    });

    $(document).on('click', '.subtract-edit', (event) => {
        event.preventDefault();
        handleTechnicianCountChange(event, -1);
    });

    const debounceFn = debounce((userCount, elementPrefix) => {
        changeTechnicianCount(elementPrefix, userCount);
    }, 1000);

    function debounce(func, wait, immediate) {
        var timeout;
        return function () {
            var context = this;
            var args = arguments;
            var later = function () {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    }

    function handleTechnicianCountChange(event, changeValue) {
        const elementPrefix = getIdFromEvent(event);
        const userCount =
            parseInt($(`#${elementPrefix}_show-on-click input`).val()) +
            changeValue;
        setTechnicianCountText(elementPrefix, userCount);
        debounceFn(userCount, elementPrefix);
    }

    $(document).on('click', '.reject-value', (event) => {
        const id = getIdFromEvent(event);
        discard(event, id, $(`#${id}_form-mode`).val());
    });

    $(document).on('click', '.accept-value', (event) => {
        const id = getIdFromEvent(event);
        saveAndClose(event, id);
    });

    $(document).on('click', '.add-slot', (event) => {
        const id = getIdFromEvent(event);
        enableEditing(id);
    });

    $(document).on('click', '.logout', () => {
        logout();
    });

    $(document).on('change', '.file-upload input[type=file]', (fileData) => {
        const { files } = fileData.target;
        $('#uploadFiles')[0].append(files);

        $.map(files, (file) => {
            const fileName = file.name;
            $('.attachments').prepend(
                `<div class='attachment'>` +
                    `<img src="images/file.svg">` +
                    `<span class="attachmentName" >${fileName}</span>` +
                    `</div>`,
            );
        });
    });

    $(document).on('change', '.slot-start-time.select', () => {
        updateSlotEndTime();
    });

    function getIdFromEvent(event) {
        const splitEventId = event.target.id.split('_');
        const targetId = `${splitEventId[0]}_${splitEventId[1]}`;
        return targetId;
    }
});
