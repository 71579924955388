import $ from 'jquery';
import moment from 'moment';
import 'datatables.net';
import 'datatables.net-dt/css/jquery.dataTables.css';

import { getClientId } from '../auth-manager';
import { getTeamId } from '../team-manager';
import { baseUrl } from '../common';
import { postApi, getApi } from '../api-manager';
import { SETTING_TYPES } from './constants';

window.$ = window.jQuery = require('jquery');

function getStartDateAndTime() {
    const startDateSelector = $('.audit-history-view .start-date');
    const startDate = startDateSelector.val();
    return moment(startDate, 'DD MMM YYYY').format();
}

function getEndDateAndTime() {
    const endDateSelector = $('.audit-history-view .end-date');
    const endDate = endDateSelector.val();
    return moment(endDate, 'DD MMM YYYY')
        .format()
        .replace('00:00:00', '23:59:59');
}

export function renderTableView(tableConfig) {
    let isInitialized = false;
    const clientId = getClientId();
    const teamId = getTeamId();
    let page = 1;
    let size = 25;
    let totalNumberOfPages = 1;
    const loader = $('.audit-history-view .loader');

    const url = `${baseUrl}/client/${clientId}/slot-audit-history`;

    const tableViewSelector = $('.audit-history-view .table-view');
    const tableDomStr = `
    <div class="table-wrapper">
    <table id="audit-history-table">
    </table>
    </div>`;

    tableViewSelector.html(tableDomStr);
    const tableSelector = $('.audit-history-view #audit-history-table');
    tableSelector.DataTable({
        initComplete: () => {
            isInitialized = true;
            const pageNumberButton = $('.pagination-bar .pageNumberButton');
            const gotoPageButton = $(
                '.pagination-bar .gotoPage .gotoPageButton',
            );
            const gotoPageInput = $('.pagination-bar .gotoPage input');
            const nextButton = $('.pagination-bar .next-button');
            const backButton = $('.pagination-bar .back-button');
            const dropDown = $('.pagination-bar .dropdown-menu');

            pageNumberButton.bind('click', function () {
                const value = $(this).attr('data-value');
                page = Number(value);
                PaginationBar.setPageNumberButton(page);
                PaginationBar.setNextButton(page, totalNumberOfPages);
                PaginationBar.setBackButton(page);
                tableSelector.DataTable().ajax.reload();
            });
            gotoPageButton.bind('click', () => {
                const value = gotoPageInput.val();
                page = Number(value);
                PaginationBar.setPageNumberButton(page);
                PaginationBar.setNextButton(page, totalNumberOfPages);
                PaginationBar.setBackButton(page);
                tableSelector.DataTable().ajax.reload();
            });

            nextButton.bind('click', () => {
                page = page + 1;
                PaginationBar.setNextButton(page, totalNumberOfPages);
                PaginationBar.setBackButton(page);
                PaginationBar.setPageNumberButton(page);
                tableSelector.DataTable().ajax.reload();
            });

            backButton.bind('click', () => {
                page = page - 1;
                PaginationBar.setBackButton(page);
                PaginationBar.setNextButton(page, totalNumberOfPages);
                PaginationBar.setPageNumberButton(page);
                tableSelector.DataTable().ajax.reload();
            });
            dropDown.bind('click', (e) => {
                let value = e.target.innerText;
                try {
                    value = Number(value);
                    if (size !== value) {
                        size = value;
                        PaginationBar.setDropdown(size);
                        tableSelector.DataTable().ajax.reload();
                    }
                } catch (err) {
                    console.log(err);
                }
            });
        },
        scrollCollapse: true,
        scrollY: 'calc(100vh - 280px)',
        fixedHeader: true,
        info: false,
        lengthChange: false,
        searching: false,
        paging: false,
        pageLength: 10,
        processing: false,
        serverSide: true,
        language: {
            processing: 'Loading...',
            emptyTable: 'No Data Available',
        },
        ajax(data, callback) {
            loader.show();
            postApi(url, {
                skipPaginationInfo: false,
                size,
                page,
                info: false,
                sortingInfo: [
                    {
                        name: 'date',
                        order: data.order[0].dir.toUpperCase(),
                    },
                ],
                filters: [
                    {
                        name: 'date',
                        operation: 'GREATER_THAN_OR_EQUAL_TO',
                        value: getStartDateAndTime(),
                        values: [],
                        allowEmptyOrNull: false,
                        caseSensitive: false,
                    },
                    {
                        name: 'date',
                        operation: 'LESSER_THAN_OR_EQUAL_TO',
                        value: getEndDateAndTime(),
                        values: [],
                        allowEmptyOrNull: false,
                        caseSensitive: false,
                    },
                    {
                        name: 'teamId.teamId',
                        operation: 'EQUALS',
                        values: [teamId],
                        allowEmptyOrNull: false,
                        caseSensitive: false,
                    },
                    ...tableConfig.filters,
                ],
            }).done((res) => {
                if (tableConfig.type === SETTING_TYPES.SLOT_SETTINGS) {
                    const { slotManagementAudits } = res;
                    const dateList = slotManagementAudits.map((audit) =>
                        moment(audit.existingValue.slotId.dayId.slotDate),
                    );
                    const minDate = moment.min(dateList).format('YYYY-MM-DD');
                    const maxDate = moment.max(dateList).format('YYYY-MM-DD');
                    const slotUrl = `${baseUrl}/client/${clientId}/slot-user-count?startDate=${minDate}&endDate=${maxDate}&teamId=${teamId}`;
                    getApi(slotUrl).done((slotsRes) => {
                        slotManagementAudits.forEach((audit) => {
                            const slotIdObj = audit.existingValue.slotId;
                            const slotsBelongToADayObj = slotsRes.daySlots.find(
                                (daySlot) =>
                                    daySlot.dayId.slotDate ===
                                    slotIdObj.dayId.slotDate,
                            );
                            const slotsBelongToADay =
                                slotsBelongToADayObj &&
                                slotsBelongToADayObj.slots;
                            const slotObj = (slotsBelongToADay || []).find(
                                (slot) => slot.slotId === slotIdObj.slotId,
                            );
                            const slot = slotObj && slotObj.slot;
                            const startTime = slot && slot.startTime;
                            const endTime = slot && slot.endTime;
                            audit.existingValue.startTime = startTime;
                            audit.existingValue.endTime = endTime;
                        });
                        const { numberOfPages, total } = res.paginationInfo;
                        totalNumberOfPages = numberOfPages;
                        loader.hide();
                        if (!isInitialized) {
                            PaginationBar.render(res);
                            $('.audit-history-view .total-entities').text(
                                `Total: ${total} entities`,
                            );
                        }
                        callback({
                            data: res.slotManagementAudits,
                        });
                    });
                } else {
                    const { numberOfPages, total } = res.paginationInfo;
                    totalNumberOfPages = numberOfPages;
                    loader.hide();
                    if (!isInitialized) {
                        PaginationBar.render(res);
                        $('.audit-history-view .total-entities').text(
                            `Total: ${total} entities`,
                        );
                    }
                    callback({
                        data: res.slotManagementAudits,
                    });
                }
            });
        },
        columns: tableConfig.columns,
        order: [[0, 'asc']],
    });
}

const PaginationBar = {
    render(res) {
        let pageNumberButtons = '';
        const { numberOfPages, currentPage } = res.paginationInfo;
        const isNextDisabled = currentPage >= numberOfPages;
        const isBackDisabled = currentPage <= 1;
        for (let i = 0; i < numberOfPages; i++) {
            if (i > 9) {
                if (i < numberOfPages - 2) {
                    continue;
                } else if (i < numberOfPages - 1) {
                    pageNumberButtons += `<div class='relative pull-left mr-1'> ... </div>`;
                    continue;
                }
            }
            pageNumberButtons += `<div data-value='${
                i + 1
            }' id='pageNumberButton-${
                i + 1
            }' class='relative pull-left pageNumberButton ${
                i < numberOfPages - 1 ? 'mr-1' : ''
            } ${i === 0 ? 'selected' : ''}'> ${i + 1} </div>`;
        }
        const paginationBar = `
    <div class="row-wrapper">
        <div class="row">
            <div class="col-md-3">
                <div class="row center-y">
                    <div class='relative pull-left mr-1 grey-900 font-weight-600'>Show</div>
                    <div class='relative pull-left mr-1'>
                        <div class='dropup'>
                            <button class="btn dropdown-toggle" type="button" data-toggle="dropdown">
                                <span class="page-size">25</span>
                                <div class="arrow down"></div>
                            </button>
                            <ul class="dropdown-menu">
                                <li>25</li>
                                <li>50</li>
                                <li>100</li>
                            </ul>
                        </div>
                    </div>
                    <div class='relative pull-left grey-900'>items on page</div>
                </div>
            </div>
            <div class="col-md-6 grey-600 font-weight-600 center-x-y">
                <div class='relative pull-left mr-2 cursor-pointer back-button ${
                    isBackDisabled ? 'disabled' : ''
                }'>
                    <div class='relative pull-left arrow left'></div>
                    <div class='relative pull-left'> BACK</div>
                </div>
                <div class='relative pull-left mr-2 cursor-pointer'>
                    ${pageNumberButtons}
                </div>
                <div class='relative pull-left cursor-pointer next-button ${
                    isNextDisabled ? 'disabled' : ''
                }'>
                    <div class='relative pull-left'> NEXT</div>
                    <div class='relative pull-left arrow'></div>
                </div>
            </div>
            <div class="col-md-3 gotoPage p-0">
                 <div class='relative font-weight-600 grey-900 mr-1'>
                    Go to page
                </div>
                <div class='relative mr-1'>
                    <input type='number'/>
                </div>
                 <div class='relative gotoPageButton center-x-y disabled'>
                    <div class='relative gotoPageButtonImg '></div>
                </div>
            </div>
        </div>
    </div>`;
        $('.pagination-bar').html(paginationBar);
        const gotoPageInput = $('.pagination-bar .gotoPage input');
        const gotoPageButton = $('.pagination-bar .gotoPage .gotoPageButton');
        const dropdownToggle = $('.pagination-bar .dropdown-toggle');
        const dropdownArrow = $('.pagination-bar .dropdown-toggle .arrow');
        gotoPageInput.bind('input', function () {
            const value = $(this).val();
            gotoPageButton.removeClass('disabled');
            if (!value || value > numberOfPages) {
                gotoPageButton.addClass('disabled');
            }
        });
        dropdownToggle.bind('click', () => {
            if (dropdownArrow.hasClass('up')) {
                dropdownArrow.removeClass('up');
                dropdownArrow.addClass('down');
                return;
            }
            dropdownArrow.removeClass('down');
            dropdownArrow.addClass('up');
        });
    },
    setNextButton(page, totalNumberOfPages) {
        const nextButton = $('.pagination-bar .next-button');
        nextButton.removeClass('disabled');
        if (page >= totalNumberOfPages) {
            nextButton.addClass('disabled');
        }
    },
    setBackButton(page) {
        const backButton = $('.pagination-bar .back-button');
        backButton.removeClass('disabled');
        if (page <= 1) {
            backButton.addClass('disabled');
        }
    },
    setPageNumberButton(page) {
        const pageNumberButton = $('.pagination-bar .pageNumberButton');
        pageNumberButton.removeClass('selected');
        $(`.pagination-bar #pageNumberButton-${page}`).addClass('selected');
    },
    setDropdown(pageSize) {
        const pageSizeNode = $('.pagination-bar .dropdown-toggle .page-size');
        const arrow = $('.pagination-bar .dropdown-toggle .arrow');
        pageSizeNode.html(pageSize);
        arrow.removeClass('up');
        arrow.addClass('down');
    },
};
