import EnvConfig from 'env-config';

const getConfig = () => {
    if (EnvConfig.env !== 'devo') {
        return EnvConfig;
    }

    // NOTE: in devo.json endPoint is template with {devo} as placeholder
    const apiEndPoint = EnvConfig.apiEndPoint.replace('{devo}', EnvConfig.devo);
    const platformEndPoint = EnvConfig.platformEndPoint.replace(
        '{devo}',
        EnvConfig.devo,
    );
    const dashboardEndPoint = EnvConfig.dashboardEndPoint.replace(
        '{devo}',
        EnvConfig.devo,
    );

    return {
        ...EnvConfig,
        apiEndPoint,
        platformEndPoint,
        dashboardEndPoint,
    };
};

export default getConfig();
