import createAuth0Client from '@auth0/auth0-spa-js';
import config from './config';
import queryString from 'query-string';
import Cookies from 'js-cookie';
import { getApi } from './api-manager';
import { renderBasicErrorPopup, renderBasicPopup } from './common';
import { platformConfig } from './platformConfig';
import Cache from './Cache';

const tokenCache = new Cache('auth.token');

var auth0 = (function () {
    let instance;

    function init(clientId) {
        instance = createAuth0Client({
            login_hint: `?${clientId}`,
            domain: config.auth0.domain,
            client_id: config.auth0.client_id,
            audience: config.auth0.audience,
            redirect_uri: `${window.location.origin}/login_callback.html?clientId=${clientId}`,
        });
    }

    return {
        create(clientId) {
            if (!instance) {
                init(clientId);
            }

            return instance;
        },
    };
})();

export async function logout(clientId, returnTo) {
    tokenCache.clear();
    const auth = await auth0.create(clientId);
    auth.logout({
        returnTo,
    });
}

export async function login({ clientId, connection }) {
    const auth = await auth0.create(clientId);
    const { providerId } = queryString.parse(location.search);
    const loginFlowVersion = platformConfig
        .getPlatformConfig()
        .getLoginFlowVersion();
    const fragment = queryString.stringify({
        loginFlowVersion,
        locusClientId: clientId,
        // NOTE: passing the devo so ULP can connect to same devo
        devo: config.env === 'devo' ? config.devo : undefined,
        locusProviderId: providerId || undefined,
        connection: connection || undefined,
    });
    return auth.loginWithRedirect({
        appState: { currentLocation: location },
        fragment,
        redirectMethod: 'replace',
    });
}

export async function isAuthenticated(clientId) {
    const auth = await auth0.create(clientId);
    return auth ? await auth.isAuthenticated() : false;
}

export async function handleRedirect(clientId, callback) {
    const auth = await auth0.create(clientId);

    if (window.location.search.includes('token=')) {
        // Backend authentication flow
        const searchParams = new URLSearchParams(window.location.search);
        const token = searchParams.get('token');
        if (token) {
            tokenCache.save(token);
        }
    }

    if (window.location.search.includes('code=')) {
        try {
            if (auth) {
                await auth.handleRedirectCallback();
            }
        } catch (error) {
            return renderBasicPopup('ERROR', error, callback);
        }
    }
    if (window.location.search.includes('error=')) {
        try {
            await auth.handleRedirectCallback();
        } catch (error) {
            return renderBasicPopup('ERROR', error, callback);
        }
    }
    /* Check if user is authenticated - returns boolean */
    const isAuthenticated = auth
        ? tokenCache.read() || (await auth.isAuthenticated())
        : false;
    // this.setIsAuthenticated(isAuthenticated);
    if (isAuthenticated) {
        /* get user token */
        const accessToken =
            tokenCache.read() || (await auth.getTokenSilently());
        Cookies.set('token', accessToken);

        //get personnel details from token
        const url = `${config.dashboardEndPoint}/session/me`;
        const redirectTo = `index.html?clientId=${clientId}`;
        getApi(url, {})
            .done((data) => {
                const { clientId, personnelId } = data.personnel;
                const username = `${clientId}/personnel/${personnelId}`;
                Cookies.set('username', username);
                window.location.href = redirectTo;
            })
            .fail((err) => {
                renderBasicErrorPopup(err, callback);
            });
    } else {
        window.location.href = `login.html?clientId=${clientId}`;
    }
}
