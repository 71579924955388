import bootbox from 'bootbox';
import moment from 'moment';
import $ from 'jquery';
import 'eonasdan-bootstrap-datetimepicker';

import { postApi } from './api-manager';
import { getClientId } from './auth-manager';
import {
    renderBasicPopup,
    renderBasicErrorPopup,
    format,
    subString,
    baseUrl,
} from './common';
import { getTeamId } from './team-manager';
import { fetchSlotStartOptions } from './settings-view-manager';
import { settingsManager } from './settings';

export function renderChangeShiftModal() {
    const message = fetchChangeShiftMessage();
    bootbox.confirm({
        animate: false,
        title: 'CHANGE SHIFT TIMINGS',
        message,
        buttons: {
            confirm: {
                label: 'UPDATE TIME',
                className: 'btn-primary',
            },
            cancel: {
                label: 'CANCEL',
                className: 'btn-primary',
            },
        },
        callback(result) {
            if (result) {
                renderConfirmShiftChangeModal();
                return;
            }
        },
    });

    $('.select').select2({
        minimumResultsForSearch: -1,
    });

    updateSlotEndTime();
    addDateTimePickerElements();
}

function fetchChangeShiftMessage() {
    const slotStartOptions = fetchSlotStartOptions();
    const message =
        "<form role='form' class='change_shift_form'>" +
        "<div class='row'> " +
        "<div class='col-sm-6 modal-label'> FROM </div>" +
        "<div class='col-sm-6 modal-label'> TO </div>" +
        '</div>' +
        "<div class='row'>" +
        "<div class='col-sm-6'><input class='date-picker start-date' type='text' required/> </div>" +
        "<div class='col-sm-6'><input class='date-picker end-date' type='text' required/> </div>" +
        '</div>' +
        "<div class='row modal-label' style='margin-top:12px;'>" +
        "<div class='col-sm-6'> SHIFT START TIME </div>" +
        "<div class='col-sm-6'> SHIFT END TIME </div>" +
        '</div>' +
        "<div class='row'>" +
        "<div class='col-sm-6'>" +
        `<select class='slot-start-time select'>${slotStartOptions}</select>` +
        '</div>' +
        "<div class='col-sm-6'>" +
        "<select class='slot-end-time select'>" +
        '</select>' +
        '</div>' +
        '</div>' +
        '</form>';
    return message;
}

export function updateSlotEndTime() {
    const settings = settingsManager.getSettings();
    const { endTime } = settings.getShiftLimit();
    const slotWidth = settings.getSlotWidth();
    const minSlotDuration = (settings.getMinSlotCount() * slotWidth) / 60;
    const slotDuration = slotWidth / 60;
    const shiftStart = $('.slot-start-time').val();
    let startMoment = addNhours(shiftStart, minSlotDuration, 'HH:mm');

    $('.slot-end-time option').remove();
    while (startMoment.toString() <= endTime) {
        startMoment = format(moment.utc(startMoment, 'HH:mm'), 'hh:mm a');
        $('.slot-end-time').append(new Option(startMoment, startMoment));
        startMoment = addNhours(startMoment, slotDuration, 'HH:mm');
    }
}

function addNhours(dateTime, duration, dateFormat) {
    const incrementedTime = moment
        .utc(dateTime, 'hh:mm a')
        .add(duration, 'hour');
    return format(incrementedTime, dateFormat);
}

function addDateTimePickerElements() {
    $('.date-picker').datetimepicker({
        defaultDate: moment().add(1, 'day'),
        format: 'YYYY-MM-DD           dddd',
        minDate: moment(),
        maxDate: moment().add(31, 'days'),
    });

    $('.datepicker > div:first-of-type').css('display', 'initial');
}

function renderConfirmShiftChangeModal() {
    const startDate = $('.change_shift_form .start-date').val();
    const endDate = $('.change_shift_form .end-date').val();
    const slotStart = $('.slot-start-time').val();
    const slotEnd = $('.slot-end-time').val();

    bootbox.dialog({
        animate: false,
        title: 'CONFIRM TO PROCEED',
        message: confirmShiftMessage(startDate, endDate, slotStart, slotEnd),
        buttons: {
            cancel: {
                label: 'BACK',
                className: 'btn-primary',
                callback() {
                    renderChangeShiftModal();
                },
            },
            ok: {
                label: 'CONFIRM',
                className: 'btn-primary',
                callback() {
                    updateShift(startDate, endDate, slotStart, slotEnd);
                },
            },
        },
    });
}

function confirmShiftMessage(startDate, endDate, slotStart, slotEnd) {
    const message =
        '<div>You are about to make changes to the shift timings.</div>' +
        "<br><div class='row'>" +
        "<div class='col-sm-3 modal-label'>FROM </div>" +
        `<div class='col-sm-6'>${startDate}</div>` +
        '</div>' +
        "<div class='row'> " +
        "<div class='col-sm-3 modal-label'> TO </div>" +
        `<div class='col-sm-6'>${endDate}</div>` +
        '</div>' +
        "<div class='row'> " +
        "<div class='col-sm-3 modal-label'> START </div>" +
        `<div class='col-sm-6'>${slotStart}</div>` +
        '</div>' +
        "<div class='row'> " +
        "<div class='col-sm-3 modal-label'> END </div>" +
        `<div class='col-sm-6'>${slotEnd}</div>` +
        '</div>';
    return message;
}
export function updateShift(startDate, endDate, slotStart, slotEnd) {
    const clientId = getClientId();
    const teamId = getTeamId();

    const shiftJson = formShiftJson(startDate, endDate, slotStart, slotEnd);
    const url = `${baseUrl}/client/${clientId}/slot-shift?teamId=${teamId}`;

    return postApi(url, shiftJson)
        .done((data) => {
            bootbox.hideAll();
            renderBookingsResponses(data);
            $('#calendar').fullCalendar('refetchEvents');
        })
        .fail((err) => {
            bootbox.hideAll();
            renderBasicErrorPopup(err);
        });
}

function formShiftJson(startDate, endDate, slotStart, slotEnd) {
    const startTime = moment(slotStart, 'hh:mm a').format('HH:mm');
    const endTime = moment(slotEnd, 'hh:mm a').format('HH:mm');

    const shiftJson = {
        shift: {
            startTime,
            endTime,
        },
        startDate: subString(startDate, 0, 10),
        endDate: subString(endDate, 0, 10),
    };
    return shiftJson;
}

function renderBookingsResponses(data) {
    const { skippedDates } = data;
    if (skippedDates.length === 0) {
        renderSuccessPopup();
        return;
    }
    renderPartialSuccessPopup(skippedDates);
}

function renderSuccessPopup() {
    const title = 'CHANGE SHIFT DETAILS';
    const message =
        'Shift details has been successfully changed for the selected date range.';
    renderBasicPopup(title, message);
}

function renderPartialSuccessPopup(skippedDates) {
    const title = 'OLD BOOKINGS FOUND';
    const message = fetchSkippedDatesHtml(skippedDates);
    renderBasicPopup(title, message);
}

function fetchSkippedDatesHtml(skippedDates) {
    let skippedDatesHtml = "<div class='skipped-dates'> ";
    skippedDates.forEach((date) => {
        skippedDatesHtml += `<div class='skipped_date' ><img src='images/error.svg'/>${date}</div>`;
    });
    skippedDatesHtml += '</div>';

    const message =
        `<div style='font-size:13px; margin-bottom:7px' >` +
        ` Shift details can't be changed for date range selected as there` +
        `are already some old bookings in between the selected range.` +
        ` Delete the old bookings from following dates and retry. </div>'${skippedDatesHtml}`;

    return message;
}
