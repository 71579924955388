export const settingsManager = (function () {
    var instance;

    function init(slotSettings) {
        return {
            getSlotStartOptions() {
                return slotSettings.slotStartOptions;
            },

            getDefaultUserCount() {
                return slotSettings.userCount;
            },

            getSlotEditBufferDuration() {
                return slotSettings.slotEditBufferDuration;
            },

            getShift() {
                return slotSettings.shift;
            },

            getAssignmentBufferDuration() {
                return slotSettings.assignmentBufferDuration;
            },

            getAdditionalTat() {
                return slotSettings.additionalTat;
            },

            getSlotWidth() {
                return slotSettings.slotWidth;
            },

            getShiftLimit() {
                return slotSettings.shiftLimit;
            },

            getEnabledUsersCount() {
                return slotSettings.enabledUserCount;
            },

            getMinUserCount() {
                return slotSettings.minUserCount;
            },

            getMinSlotCount() {
                return slotSettings.minSlotCount;
            },

            getPossibleSlotStartOptions() {
                return slotSettings.possibleSlotStartOptions;
            },
        };
    }

    return {
        getSettings() {
            return instance;
        },
        updateSettings(slotSettings) {
            instance = init(slotSettings);
            return instance;
        },
    };
})();
