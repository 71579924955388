import moment from 'moment';
import bootbox from 'bootbox';
import config from './config';

export const baseUrl = config.apiEndPoint;
export const platformBaseUrl = config.platformEndPoint;

export function renderBasicErrorPopup(error, callback) {
    let message = '';
    if (error.responseJSON) {
        message = error.responseJSON.message;
    } else {
        message = 'Something went wrong. Please Try again.';
    }
    renderBasicPopup('ERROR', message, callback);
}

export function renderBasicPopup(title, message, callback) {
    bootbox.alert({
        animate: false,
        title,
        message,
        callback,
    });
}

export function getQueryParams(queryStrings) {
    const queryParams = {};
    const params = queryStrings.split('&');

    for (let i = 0; i < params.length; i++) {
        const pair = params[i].split('=');
        queryParams[pair[0]] = decodeURIComponent(pair[1]);
    }
    return queryParams;
}

export function extractClientId() {
    var url = window.location.href;
    var matchedUrl = url.match(/\https:\/\/(.*?).slot-management.locus.sh\b/);

    if (matchedUrl) {
        return matchedUrl[1];
    }

    var urlParams = {};
    url.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) => {
        urlParams[key] = value;
    });
    var { clientId } = urlParams;
    return clientId;
}

export function getMomentFromTime(time) {
    return getMoment(time, 'hh:mm');
}

export function getMoment(dateTime, format) {
    return moment(dateTime, format);
}

export function formatDate(date) {
    return format(date, 'YYYY-MM-DD');
}

export function formatTime(time) {
    return format(time, 'hh:mm');
}

export function format(dateTime, format) {
    return dateTime.format(format);
}

export function formDateObj(date, time) {
    return new Date(`${date} ${time}`);
}

export function subString(string, startIndex, endIndex) {
    return string.substring(startIndex, endIndex);
}

export function formatSlotOptions(slotOptionsList) {
    var formatSlotOptions = slotOptionsList.map((option) =>
        moment(option, 'HH:mm').format('hh:mm a'),
    );
    return formatSlotOptions.join();
}

/**
 * Generates URL while maintaining query string in URL
 */
export function generateURL(nextRoute, event) {
    let nextUrl = window.location.origin + nextRoute;
    // if (event.querystring) {
    if (window.location.search) {
        nextUrl += window.location.search;
    }
    return nextUrl;
}

export function insertUrlParam(key, value) {
    if (history.pushState) {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set(key, value);
        const newurl = `${window.location.protocol}//${window.location.host}${
            window.location.pathname
        }?${searchParams.toString()}`;
        window.history.pushState({ path: newurl }, '', newurl);
    }
}

export function getUrlParams() {
    const vars = [];
    let hash;
    const hashes = window.location.href
        .slice(window.location.href.indexOf('?') + 1)
        .split('&');
    for (var i = 0; i < hashes.length; i++) {
        hash = hashes[i].split('=');
        vars.push(hash[0]);
        vars[hash[0]] = hash[1];
    }
    return vars;
}
