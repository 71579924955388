import $ from 'jquery';
import bootbox from 'bootbox';
import moment from 'moment';
import 'select2';

import { postApi } from './api-manager';
import { getClientId } from './auth-manager';
import {
    renderBasicErrorPopup,
    formatDate,
    baseUrl,
    formatSlotOptions,
} from './common';
import { settingsManager } from './settings';
import { getTeamId } from './team-manager';
import { updateShift, updateSlotEndTime } from './update-shift';
import { ALLOW_EDIT_SETTINGS_BY_DESIGNATION } from './designation';
import { DEFAULT_SETTINGS } from './constants';

window.$ = window.jQuery = require('jquery');

export function renderEditModeData(personnelDesignation) {
    const defaultSettings = ALLOW_EDIT_SETTINGS_BY_DESIGNATION.DEFAULT_SETTINGS;
    const allDefaultSettingFields = Object.keys(defaultSettings);
    const renderPopupBySettingName = {
        [DEFAULT_SETTINGS.TECHNICIAN_COUNT]: renderChangeTechnicianPopup,
        [DEFAULT_SETTINGS.MIN_TECHNICIAN_COUNT]: renderChangeMinTechnicianPopup,
        [DEFAULT_SETTINGS.SHIFT]: renderChangeShiftPopup,
        [DEFAULT_SETTINGS.SLOT_OPTIONS]: renderChangeSlotOptionsPopup,
        [DEFAULT_SETTINGS.TRAVEL_TIME]: renderTravelTimePopup,
    };
    allDefaultSettingFields.forEach((field) => {
        if (defaultSettings[field].includes(personnelDesignation)) {
            $(document).on('click', `#${field}`, () => {
                event.preventDefault();
                renderPopupBySettingName[field]();
            });
            $(`#${field}`).addClass('editable');
        }
    });
}

function renderChangeShiftPopup() {
    const message = changeShiftMessage();
    bootbox.dialog({
        animate: false,
        title: 'CONFIRM TO PROCEED',
        message,
        buttons: {
            ok: {
                label: 'CONFIRM',
                className: 'btn-primary',
                callback() {
                    const shift = {};
                    const slot = {};
                    const activateNow = $('.active_now').is(':checked');

                    shift.startTime = moment(
                        $('.slot-start-time').val(),
                        'hh:mm a',
                    ).format('HH:mm');
                    shift.endTime = moment(
                        $('.slot-end-time').val(),
                        'hh:mm a',
                    ).format('HH:mm');
                    slot.shift = shift;
                    updateSlotSettings(slot, activateNow);
                },
            },
            cancel: {
                label: 'CANCEL',
                className: 'btn-primary',
            },
        },
    });

    $('.select').select2({
        minimumResultsForSearch: -1,
    });

    updateSlotEndTime();
}

function changeShiftMessage() {
    const slotStartOptions = fetchSlotStartOptions();
    const message =
        `<div> Do you want to change the default shift timings. ` +
        'This will be active for all the dates beyond the ongoing week/s.</div><br>' +
        "<div><input type='checkbox' class='active_now'> &nbsp; Activate From Tomorrow </div><br>" +
        "<div class='row'>" +
        "<div class='col-sm-6 modal-label'> SHIFT START TIME </div>" +
        "<div class='col-sm-6 modal-label'> SHIFT END TIME </div>" +
        '</div>' +
        "<div class='row'>" +
        "<div class='col-sm-6'>" +
        `<select class='slot-start-time select'>${slotStartOptions}</select>` +
        '</div>' +
        "<div class='col-sm-6'>" +
        "<select class='slot-end-time select'>" +
        '</select>' +
        '</div>' +
        '</div>';
    return message;
}

function renderChangeTechnicianPopup() {
    const message = changeTechniciansMessage();
    bootbox.dialog({
        animate: false,
        title: 'CONFIRM TO PROCEED',
        message,
        buttons: {
            ok: {
                label: 'CONFIRM',
                className: 'btn-primary',
                callback() {
                    const slot = {};
                    slot.userCount = parseInt($('#new-technician-count').val());
                    const activateNow = $('.active_now').is(':checked');
                    updateSlotSettings(slot, activateNow);
                },
            },
            cancel: {
                label: 'CANCEL',
                className: 'btn-primary',
            },
        },
    });
}

function changeTechniciansMessage() {
    const message =
        `<div> Do you want to change the default technicians. ` +
        'This will be active for all the dates beyond the ongoing week/s.</div><br>' +
        "<div><input type='checkbox' class='active_now'> &nbsp; Activate From Tomorrow </div><br>" +
        "<div class='row'>" +
        "<div class='col-sm-6 modal-label'> TECHNICIAN COUNT </div>" +
        '</div>' +
        "<div class='row'>" +
        "<div class='col-sm-6'>" +
        "<input type='number' min='0' id='new-technician-count'>" +
        '</div>';

    return message;
}

function renderChangeMinTechnicianPopup() {
    const message = changeMinTechnicianMessage();
    bootbox.dialog({
        animate: false,
        title: 'CONFIRM TO PROCEED',
        message,
        buttons: {
            ok: {
                label: 'CONFIRM',
                className: 'btn-primary',
                callback() {
                    const slot = {};
                    slot.minUserCount = parseInt(
                        $('#new-min-technician-count').val(),
                    );
                    const activateNow = $('.active_now').is(':checked');
                    updateSlotSettings(slot, activateNow);
                },
            },
            cancel: {
                label: 'CANCEL',
                className: 'btn-primary',
            },
        },
    });
}

function changeMinTechnicianMessage() {
    const message =
        `<div> Do you want to change the mininimum technician count?</div>` +
        '<div> Note: This change will be effective immediately. </div><br>' +
        "<div class='row'>" +
        "<div class='col-sm-6 modal-label'> MIN TECHNICIAN COUNT </div>" +
        '</div>' +
        "<div class='row'>" +
        "<div class='col-sm-6'>" +
        "<input type='number' min='0' id='new-min-technician-count'>" +
        '</div>';

    return message;
}

export function fetchSlotStartOptions() {
    const settings = settingsManager.getSettings();
    const options = settings.getSlotStartOptions();
    const formattedOptions = options.map((option) =>
        moment(option, 'HH:mm').format('hh:mm a'),
    );

    let slotStartOptionsHtml = '';
    formattedOptions.forEach((option) => {
        slotStartOptionsHtml += new Option(option, option).outerHTML;
    });

    return slotStartOptionsHtml;
}

export function updateSlotSettings(slot, activateNow) {
    if (!activateNow) {
        updateSettings(slot);
        return;
    }

    activateFromTomorrow(slot);
}

export function updateSettings(slot) {
    const clientId = getClientId();
    const teamId = getTeamId();

    const url = `${baseUrl}/client/${clientId}/slot-settings?teamId=${teamId}`;

    return postApi(url, slot)
        .done((data) => {
            settingsManager.updateSettings(data.settings);
            const {
                userCount,
                shift,
                minUserCount,
                slotStartOptions,
                additionalTat,
            } = data.settings;

            const shiftStartTime = moment(shift.startTime, 'HH:mm').format(
                'hh:mm a',
            );
            const shiftEndTime = moment(shift.endTime, 'HH:mm').format(
                'hh:mm a',
            );
            $('#technician-count').text(userCount);
            $('#min-technician-count').text(minUserCount);
            $('#shift').text(`${shiftStartTime} - ${shiftEndTime}`);
            $('#slot-options').text(formatSlotOptions(slotStartOptions));
            $('#travel-time').text(additionalTat / 60);
        })
        .fail((err) => {
            renderBasicErrorPopup(err);
        });
}

function changeSlotOptionsPopup() {
    const possibleSlotOptions = fetchPossibleSlotStartOptions();
    const message =
        `<div> Do you want to change the start slot options. ` +
        'This will be active for all the dates beyond the ongoing week/s.</div><br>' +
        "<div><input type='checkbox' class='active_now'> &nbsp; Activate From Tomorrow </div><br>" +
        "<div class='row'>" +
        "<div class='col-sm-6 modal-label'> SLOT START OPTIONS </div>" +
        '</div>' +
        "<div class='row'>" +
        "<div class='col-sm-6'>" +
        `<select class='slot-start-options select' multiple>${possibleSlotOptions}</select>` +
        '</div>';
    '</div>' + '</div>';
    return message;
}

function renderChangeSlotOptionsPopup() {
    const message = changeSlotOptionsPopup();
    bootbox.dialog({
        animate: false,
        title: 'CONFIRM TO PROCEED',
        message,
        buttons: {
            ok: {
                label: 'CONFIRM',
                className: 'btn-primary',
                callback() {
                    const startTime = $('.slot-start-options').val();
                    const activateNow = $('.active_now').is(':checked');
                    updateSlotOptionsSettings(startTime, activateNow);
                },
            },
            cancel: {
                label: 'CANCEL',
                className: 'btn-primary',
            },
        },
    });

    $('.select').select2({
        minimumResultsForSearch: -1,
    });
}

function fetchPossibleSlotStartOptions() {
    const settings = settingsManager.getSettings();
    const options = settings.getPossibleSlotStartOptions();
    const formattedOptions = options.map((option) =>
        moment(option, 'HH:mm').format('hh:mm a'),
    );

    let slotStartOptionsHtml = '';
    formattedOptions.forEach((option) => {
        slotStartOptionsHtml += new Option(option, option).outerHTML;
    });

    return slotStartOptionsHtml;
}

function updateSlotOptionsSettings(slotList, activateNow) {
    const formattedSlotOptionsList = [];
    $.each(slotList, (key, slotOption) => {
        console.log(slotOption);
        formattedSlotOptionsList.push(
            moment(slotOption, 'hh:mm a').format('HH:mm'),
        );
    });

    const slotOptions = {
        slotStartOptions: formattedSlotOptionsList,
    };
    if (!activateNow) {
        updateSettings(slotOptions);
        return;
    }

    activateFromTomorrow(slotOptions);
}

function changeTravelTimePopup() {
    const message =
        `<div> Do you want to change the travel time. ` +
        'This will be active for all the dates beyond the ongoing week/s.</div><br>' +
        "<div><input type='checkbox' class='active_now'> &nbsp; Activate From Tomorrow </div><br>" +
        "<div class='row'>" +
        "<div class='col-sm-6 modal-label'> Travel time ( in mins ) </div>" +
        '</div>' +
        "<div class='row'>" +
        "<div class='col-sm-6'>" +
        "<input type='number' min='0' id='new-travel-time'>" +
        '</div>';
    '</div>' + '</div>';
    return message;
}

function renderTravelTimePopup() {
    const message = changeTravelTimePopup();
    bootbox.dialog({
        animate: false,
        title: 'CONFIRM TO PROCEED',
        message,
        buttons: {
            ok: {
                label: 'CONFIRM',
                className: 'btn-primary',
                callback() {
                    const newTravelTime = $('#new-travel-time').val();
                    const activateNow = $('.active_now').is(':checked');
                    updateAdditionalTatSettings(newTravelTime, activateNow);
                },
            },
            cancel: {
                label: 'CANCEL',
                className: 'btn-primary',
            },
        },
    });
}

function updateAdditionalTatSettings(travelTime, activateNow) {
    const additionalTatOptions = {
        additionalTat: travelTime * 60,
    };

    if (!activateNow) {
        updateSettings(additionalTatOptions);
        return;
    }
    activateFromTomorrow(additionalTatOptions);
}

function activateFromTomorrow(settingsJson) {
    const splitShift = $('#shift').text().split(' - ');
    const startTime = splitShift[0];
    const endTime = splitShift[1];
    const startDate = formatDate(moment().add(1, 'day'));
    const endDate = formatDate(moment().add(30, 'day'));
    updateSettings(settingsJson).done(() => {
        updateShift(startDate, endDate, startTime, endTime);
    });
}
