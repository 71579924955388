import moment from 'moment';
import { SETTING_TYPES } from './constants';

const defaultSettingTypesMap = {
    userCount: 'Default Technician Allocation Count',
    minUserCount: 'Minimum Technician Count',
    shift: 'Shift',
    slotStartOptions: 'Slot Start Options',
    additionalTat: 'Travel Time',
};

const getModifiedSettingName = (data) => {
    const allFields = Object.keys(data);
    for (const fieldName of allFields) {
        if (!data[fieldName]) {
            continue;
        }

        if (Array.isArray(data[fieldName]) && data[fieldName].length === 0) {
            continue;
        }

        return defaultSettingTypesMap[fieldName];
    }
};

const getModifiedSettingValue = (data) => {
    if (!data) {
        return null;
    }
    const allFields = Object.keys(data);
    for (const fieldName of allFields) {
        if (!data[fieldName]) {
            continue;
        }

        if (fieldName === 'additionalTat') {
            data[fieldName] = moment
                .duration(data[fieldName], 'seconds')
                .humanize()
                .replace('minute', 'min');
        }

        if (Array.isArray(data[fieldName])) {
            if (!data[fieldName].length) continue;
            const size = data[fieldName].length;
            data[fieldName] = data[fieldName].reduce((acc, time, index) => {
                acc += moment(time, 'h:mm a').format('h:mm a');
                if (index < size - 1) {
                    acc += ', ';
                }
                return acc;
            }, '');
            return data[fieldName];
        }

        return data[fieldName];
    }
};

const defaultSettings = {
    type: SETTING_TYPES.DEFAULT_SETTINGS,
    columns: [
        {
            title: 'Modified on',
            data: 'updatedOn',
            defaultContent: 'Not Available',
            render: (data) => {
                return moment(data).format('DD MMM YYYY, hh:mm a');
            },
        },
        {
            title: 'Modified by',
            data: 'actor.id',
            orderable: false,
            defaultContent: 'Not Available',
        },
        {
            title: 'Setting name',
            data: 'updatedValue',
            orderable: false,
            defaultContent: 'Not Available',
            render: (data) => {
                return getModifiedSettingName(data);
            },
        },
        {
            title: 'Changed from',
            data: 'existingValue',
            orderable: false,
            defaultContent: 'Not Available',
            render: (data) => {
                const value = getModifiedSettingValue(data);
                if (value && typeof value === 'object') {
                    return `${moment(value.startTime, 'h:mm a').format(
                        'h:mm a',
                    )} - ${moment(value.endTime, 'h:mm a').format('h:mm a')}`;
                }
                return value;
            },
        },
        {
            title: 'Changed to',
            data: 'updatedValue',
            orderable: false,
            defaultContent: 'Not Available',
            render: (data) => {
                const value = getModifiedSettingValue(data);
                if (value && typeof value === 'object') {
                    return `${moment(value.startTime, 'h:mm a').format(
                        'h:mm a',
                    )} - ${moment(value.endTime, 'h:mm a').format('h:mm a')}`;
                }
                return value;
            },
        },
    ],
    filters: [
        {
            name: 'updatedField',
            operation: 'EQUALS',
            value: 'SETTINGS',
            values: [],
            allowEmptyOrNull: false,
            caseSensitive: false,
        },
    ],
};

export default defaultSettings;
