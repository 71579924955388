import moment from 'moment';
import { SETTING_TYPES } from './constants';

const defaultSettings = {
    type: SETTING_TYPES.SLOT_SETTINGS,
    columns: [
        {
            title: 'Modified on',
            data: 'updatedOn',
            defaultContent: 'Not Available',
            render: (data) => {
                return moment(data).format('DD MMM YYYY, hh:mm a');
            },
        },
        {
            title: 'Modified by',
            data: 'actor.id',
            orderable: false,
            defaultContent: 'Not Available',
        },
        {
            title: 'Date',
            data: 'existingValue',
            orderable: false,
            defaultContent: 'Not Available',
            render: (data) => {
                if (!data || !data.slotId) {
                    return null;
                }
                const { slotDate } = data.slotId.dayId;
                return moment(slotDate).format('DD MMM YYYY');
            },
        },
        {
            title: 'Time slot',
            data: 'existingValue',
            orderable: false,
            defaultContent: 'Not Available',
            render: (data) => {
                if (!data || !data.startTime || !data.endTime) {
                    return null;
                }
                const { startTime } = data;
                const { endTime } = data;
                return `${moment(startTime, 'h:mm a').format(
                    'h:mm a',
                )} - ${moment(endTime, 'h:mm a').format('h:mm a')}`;
            },
        },
        {
            title: 'Changed from',
            data: 'existingValue.userCount',
            orderable: false,
            defaultContent: 'Not Available',
        },
        {
            title: 'Changed to',
            data: 'updatedValue.userCount',
            orderable: false,
            defaultContent: 'Not Available',
        },
    ],
    filters: [
        {
            name: 'updatedField',
            operation: 'EQUALS',
            value: 'COUNT',
            values: [],
            allowEmptyOrNull: false,
            caseSensitive: false,
        },
    ],
};

export default defaultSettings;
