import $ from 'jquery';
import moment from 'moment';
import 'select2';
import 'fullcalendar';
import iziToast from 'izitoast';

import { getApi, postApi } from './api-manager';
import { getClientId } from './auth-manager';
import { renderBasicErrorPopup, baseUrl, formatSlotOptions } from './common';
import { formWeekView } from './calendar-view-manager';
import { settingsManager } from './settings';
import { getTeamId, populateTeamDetails } from './team-manager';
import { personnelManager } from './personnel-manager';

export function populateCalendarView(clientId, teamId) {
    const url = `${baseUrl}/client/${clientId}/slot-settings`;
    const urlParams = { teamId };
    getApi(url, urlParams)
        .done((data) => {
            const settings = settingsManager.updateSettings(data);
            populateSlotSettings(settings);
            populateCalendarValues(clientId, teamId);
        })
        .fail((err) => {
            renderBasicErrorPopup(err);
        });
}

function populateSlotSettings(settings) {
    const shift = settings.getShift();
    const enabledUserCount = settings.getEnabledUsersCount();
    const shiftStartTime = moment(shift.startTime, 'HH:mm').format('hh:mm a');
    const shiftEndTime = moment(shift.endTime, 'HH:mm').format('hh:mm a');

    $('.calender-view #active-technician-count').text(enabledUserCount);
    $('.settings-view #active-technician-count').text(enabledUserCount);

    $('#technician-count').text(settings.getDefaultUserCount());
    $('#min-technician-count').text(settings.getMinUserCount());
    $('#shift').text(`${shiftStartTime} - ${shiftEndTime}`);
    $('#time-before').text(settings.getSlotEditBufferDuration());
    $('#buffer-time').text(settings.getAssignmentBufferDuration() / 60);
    $('#travel-time').text(settings.getAdditionalTat() / 60);
    $('#slot-duration').text(settings.getSlotWidth() / 60);
    $('#slot-options').text(formatSlotOptions(settings.getSlotStartOptions()));
}

function getSlotDurationString() {
    const settings = settingsManager.getSettings();
    const slotWidth = parseInt(settings.getSlotWidth());
    const hours = parseInt(slotWidth / 60);
    const minutes = slotWidth % 60;
    return `${hours}:${minutes}:00`;
}

function populateCalendarValues(clientId, teamId) {
    const durationStr = getSlotDurationString();

    $('#calendar').fullCalendar('destroy');
    $('#calendar').fullCalendar({
        slotLabelFormat: 'hh:mm A',
        defaultView: 'agendaWeek',
        height: 'auto',
        weekNumberCalculation: 'ISO',
        allDaySlot: false,
        eventBorderColor: '#ddd;border-radius: 0px;',
        slotDuration: durationStr, // h:mm:ss
        eventColor: 'transparent',
        eventTextColor: '#383f47',
        nowIndicator: true,
        defaultDate: moment().format(),
        displayEventTime: false,
        navLinks: false,
        editable: false,
        eventLimit: true,
        eventBackgroundColor: 'rgb(255,255,255)',
        header: {
            center: 'prev,next',
            right: 'today',
        },
        viewRender() {
            populateAdditionalOptions(clientId, teamId);
        },
        eventAfterAllRender() {
            $('.fc-prev-button')
                .attr('data-toggle', 'tooltip')
                .attr('title', 'Previous week');
            $('.fc-next-button')
                .attr('data-toggle', 'tooltip')
                .attr('title', 'Next week');
            $('[data-toggle="tooltip"]').tooltip({ placement: 'bottom' });

            if ($('.calender-view .team-div').length !== 0) {
                return;
            }
            populateTeamDetails();
        },
        columnHeaderHtml(date) {
            /*
          Set heading for the calendar
      */
            const daysOfTheWeek = [
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday',
                'Sunday',
            ];
            let ddmmstate = '';
            const currentDay = moment(date).startOf('day');
            const todayDay = moment().utc().startOf('day');

            if (currentDay.isSame(todayDay)) {
                ddmmstate = 'ddmm-current';
            } else if (currentDay.isBefore(todayDay)) {
                ddmmstate = 'ddmm-past';
            }

            const formattedCalendarHeader = `${
                daysOfTheWeek[date.weekday()]
            } ${date.format('DD')}/${date.format('MM')}`;
            return `<span class="dow ${ddmmstate}">${formattedCalendarHeader}</span>`;
        },

        /*
        Editing state is when the click event happens and the technicians can be edited via keyboard
        Once the user enters editing mode
        All the hovers should be disabled until the editing mode is exited
        */
        eventMouseover(event) {
            const eventId = event.id;
            if (!$(`#${eventId}_show-when-out-of-focus`).hasClass('add-slot')) {
                $(`#${eventId}_show-when-out-of-focus`).hide();
                if (!$(`#${eventId}_show-on-click`).hasClass('editing')) {
                    $(`#${eventId}_show-on-focus`).show();
                }
            }
        },

        eventMouseout(event) {
            const eventId = event.id;
            if (!$(`#${eventId}_show-when-out-of-focus`).hasClass('add-slot')) {
                $(`#${eventId}_show-on-focus`).hide();
                if (!$(`#${eventId}_show-on-click`).hasClass('editing')) {
                    $(`#${eventId}_show-when-out-of-focus`).show();
                }
            }
        },

        eventRender(calev, elt) {
            const className = calev.className[0];
            const settings = settingsManager.getSettings();
            const slotEditBufferDuration = settings.getSlotEditBufferDuration();
            const titleMessage = calev.title;

            if (moment(calev.start).isSame(new Date(), 'day')) {
                elt.addClass('today-event');
                elt.children().addClass('today-event');
            }

            if (
                moment(calev.start).isBefore(
                    moment().add(slotEditBufferDuration, 'minute'),
                )
            ) {
                elt.html(`<div class=${className}>${titleMessage}</div>`);
                elt.addClass('past-event').addClass(className);
                elt.children().addClass('past-event').addClass(className);
            } else {
                if (className === 'empty-event' || className === 'empty-slot') {
                    elt.html(
                        '<div class="show-on-focus" style="color: green; font-size: 10px;"></div>',
                    );
                    return;
                }
                const elementPrefix = calev.id;
                const defaultTechnicianCount = settings.getDefaultUserCount();
                elt.html(
                    getEventHtml(
                        className,
                        elementPrefix,
                        titleMessage,
                        defaultTechnicianCount,
                    ),
                );
            }
        },
        events(start, end, timezone, callback) {
            const clientId = getClientId();
            const teamId = getTeamId();
            formWeekView(clientId, teamId, start, end, callback);
        },
    });

    function getEventHtml(
        className,
        elementPrefix,
        technicians,
        defaultTechnicianCount,
    ) {
        let html = getBaseHtml(className, elementPrefix, technicians);
        html += getOnFocusHtml(
            className,
            elementPrefix,
            technicians,
            defaultTechnicianCount,
        );
        html += getOnClickHtml(
            className,
            elementPrefix,
            technicians,
            defaultTechnicianCount,
        );

        return html;
    }

    function getBaseHtml(className, elementPrefix, technicians) {
        let style = '';
        if (className === 'add-slot') {
            style = "style='font-size:14px;'";
        }
        return (
            `<div ${style} class=${className}` +
            ` id=${elementPrefix}_show-when-out-of-focus>${technicians}</div>`
        );
    }

    function getOnFocusHtml(
        className,
        elementPrefix,
        technicians,
        defaultTechnicianCount,
    ) {
        const finalTechnicianCount = getTechnicianCount(
            technicians,
            defaultTechnicianCount,
            className,
        );
        return (
            `<div class='event-on-focus' id='${elementPrefix}_show-on-focus' hidden>` +
            `<a id='${elementPrefix}_sub-one' class="edit subtract-edit" href="#">-</a>&nbsp;&nbsp;` +
            `<span class="technician-number" id=${elementPrefix}_technician-count >` +
            `${finalTechnicianCount}</span>&nbsp;&nbsp;` +
            `<a id='${elementPrefix}_add-one' class="edit addition-edit" href="#">+</a>` +
            '</div>'
        );
    }

    function getOnClickHtml(
        className,
        elementPrefix,
        technicians,
        defaultTechnicianCount,
    ) {
        const finalTechnicianCount = getTechnicianCount(
            technicians,
            defaultTechnicianCount,
            className,
        );
        return (
            `<div id='${elementPrefix}_show-on-click' hidden>` +
            `<a class="edit" ` +
            'class="edit-by-type" style="font-size:20px" href="#">' +
            `<img class='reject-value' id='${elementPrefix}_reject' src="images/reject.svg">` +
            '</a>&nbsp;&nbsp;' +
            `<input style="width:30%;text-align:center;" type="number" min="0"
      id='${elementPrefix}_form-mode' value=${finalTechnicianCount} data-value=${finalTechnicianCount}>&nbsp;&nbsp;` +
            `<a class="edit" class="edit-by-type" style="font-size:20px" href="#">` +
            `<img class='accept-value' id='${elementPrefix}_accept' src="images/accept.svg">` +
            '</a>' +
            '</div>'
        );
    }
}

function getTechnicianCount(technicians, defaultTechnicianCount, className) {
    if (className === 'add-slot') {
        return defaultTechnicianCount;
    }
    return technicians;
}

export function enableEditing(elementPrefix) {
    $(`#${elementPrefix}_show-on-click`).addClass('editing');
    $(`#${elementPrefix}_show-on-focus`).hide();
    $(`#${elementPrefix}_show-when-out-of-focus`).hide();
    $(`#${elementPrefix}_show-on-click`).show();
}

export function changeTechnicianCount(elementPrefix, userCount) {
    updateSlotUserCount(elementPrefix, userCount);
}

export function discard(event, elementPrefix, technicians) {
    event.preventDefault();
    event.stopImmediatePropagation();

    $(`#${elementPrefix}_show-on-click`).removeClass('editing');
    $(`#${elementPrefix}_show-on-click`).hide();

    const hasSlots = $(`#${elementPrefix}_show-when-out-of-focus`).hasClass(
        'add-slot',
    );

    if (hasSlots) {
        $(`#${elementPrefix}_show-on-focus`).hide();
        $(`#${elementPrefix}_show-when-out-of-focus`).show();
        return;
    }

    $(`#${elementPrefix}_show-on-focus`).show();
    $(`#${elementPrefix}_show-when-out-of-focus`).hide();
    $(`#${elementPrefix}_form-mode`).val(technicians);
}

export function saveAndClose(event, elementPrefix) {
    event.preventDefault();
    const userCount = parseInt(
        $(`#${elementPrefix}_show-on-click input`).val(),
    );
    updateSlotUserCount(elementPrefix, userCount);
}

function updateSlotUserCount(elementPrefix, userCount) {
    const clientId = getClientId();
    const teamId = getTeamId();

    const slotData = elementPrefix.split('_');

    const updateSlotUsersJson = {
        slotId: {
            dayId: {
                teamId,
                slotDate: slotData[0],
            },
            slotId: slotData[1],
        },
        userCount,
    };

    const url = `${baseUrl}/client/${clientId}/slot-user-count`;

    postApi(url, updateSlotUsersJson)
        .done(() => {
            setTechnicianCountText(elementPrefix, userCount);
            $(`#${elementPrefix}_show-on-click input`).data('value', userCount);
            $(`#${elementPrefix}_show-on-click`).hide();
            $(`#${elementPrefix}_show-on-focus`).show();

            $(`#${elementPrefix}_show-on-click`).removeClass('editing');
            $(`#${elementPrefix}_show-when-out-of-focus`).removeClass(
                'add-slot',
            );
            $(`#${elementPrefix}_show-when-out-of-focus`)
                .removeAttr('style')
                .attr('style', 'display:none');

            iziToast.show({
                message: 'Technician count updated succesfully!',
                position: 'bottomCenter',
                timeout: 3000,
                backgroundColor: '#dddddd',
            });
        })
        .fail((err) => {
            var previousCount = $(`#${elementPrefix}_show-on-click input`).data(
                'value',
            );
            setTechnicianCountText(elementPrefix, previousCount);
            renderBasicErrorPopup(err);
        });
}

export function setTechnicianCountText(elementPrefix, userCount) {
    $(`#${elementPrefix}_show-on-click input`).val(userCount);
    $(`#${elementPrefix}_show-when-out-of-focus`).text(userCount);
    $(`#${elementPrefix}_show-on-focus span`).text(userCount);
}

function populateAdditionalOptions(clientId, teamId) {
    if ($('.calender-view .additional-options').length !== 0) {
        return;
    }

    var activeTechnicianCount = $('#active-technician-count').text();
    $('.fc-center').append(
        '<span class="additional-options active-technicians-div" data-toggle="tooltip" title="Active Technicians" >' +
            `<b>Active Technician Count: </b><span id="active-technician-count" class="data-field">${activeTechnicianCount}</span>` +
            '</span>',
    );

    var personnelDetails = personnelManager.createSession();
    var personnelName = personnelDetails.getName();

    $('.fc-right').prepend(
        '<span class="additional-options personnel-name-div" data-toggle="tooltip" title="Personnel Name" >' +
            `<b>Name: </b>${personnelName} ` +
            '</span>',
    );

    $('.fc-right').append(
        '<div class="additional-options"><div class="team">' +
            '<div class="team-header" > Service Region</div>' +
            '<span class="team-div">' +
            '<select class="team-selector select"></select>' +
            '</span>' +
            '</div></div>' +
            '<span class="additional-options change-shift" data-toggle="tooltip" title="Change Shift" >' +
            '<img style="cursor:pointer;" src="./images/watch.svg"/>' +
            '</span>' +
            '<span class="additional-options view-settings" data-toggle="tooltip" title="Settings" >' +
            '<img style="cursor:pointer;" src="./images/gear.svg">' +
            '</span>' +
            '<span class="additional-options logout" data-toggle="tooltip" title="Logout" >' +
            '<img style="cursor:pointer;" src="./images/logout.svg">' +
            '</span>',
    );
    $('[data-toggle="tooltip"]').tooltip({ placement: 'bottom' });

    populateTeamDetails();
    $('.team-selector').val(teamId).trigger('change');
}
