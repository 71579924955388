export default class Cache {
    constructor(key) {
        this._key = key;
    }

    save(value) {
        window.localStorage.setItem(this._key, JSON.stringify(value));
    }

    read() {
        const value = window.localStorage.getItem(this._key);
        return value ? JSON.parse(value) : null;
    }

    clear() {
        window.localStorage.removeItem(this._key);
    }
}
