import $ from 'jquery';
import 'select2';
import bootbox from 'bootbox';

import { getApi } from './api-manager';
import { getClientId } from './auth-manager';
import { populateCalendarView } from './calendar';
import { baseUrl, renderBasicPopup } from './common';
import { renderAuditHistoryView } from './audit-history-view';

var teamManager = (function () {
    var instance;
    var team;
    function init(teams) {
        team = teams[0].teamId;
        return {
            getTeamId() {
                return team;
            },
            getTeams() {
                return teams;
            },
        };
    }

    return {
        createSession(teams) {
            if (!instance) {
                instance = init(teams);
            }
            return instance;
        },
        set setTeamId(teamId) {
            team = teamId;
        },
    };
})();

export function getTeams() {
    return teamManager.createSession().getTeams();
}

export function getTeamId() {
    return teamManager.createSession().getTeamId();
}

export function getTeamName(teamId) {
    const teams = getTeams();
    const team = teams.filter((team) => team.teamId === teamId)[0];
    return team.name;
}

export function fetchTeams(clientId, personnel) {
    const url = `${baseUrl}/client/${clientId}/team`;
    return getApi(url, {}).done((allTeams) => {
        const teams = fetchPersonnelTeams(allTeams, personnel);
        if (teams.length === 0) {
            renderBasicPopup(
                'ERROR',
                "The mentioned personnel doesn't have access to any active teams.",
            );
            return;
        }
        teamManager.createSession(teams);
    });
}

function fetchPersonnelTeams(allTeams, personnel) {
    const activeTeams = allTeams.filter((team) => team.status === 'ACTIVE');
    const teamIds = personnel.teams.map((team) => team.teamId);
    const { hasAllTeamsAccess } = personnel;

    if (hasAllTeamsAccess) {
        return activeTeams;
    }
    const filteredTeams = activeTeams.filter(
        (team) => teamIds.indexOf(team.teamId) !== -1,
    );
    return filteredTeams;
}

export function populateTeamDetails() {
    const teams = getTeams();
    $('.team-selector').select2();
    $('.team-div .team-selector').empty();
    teams.forEach((team) => {
        const { teamId, name } = team;
        $('.team-div .team-selector').append(
            `<option value=${teamId}>${name}</option>`,
        );
    });
}

export function showTeamChangeModal(teamId, name) {
    bootbox.confirm({
        animate: false,
        title: 'CONFIRM TO PROCEED',
        message: `The Selected Service Region is ${name}. Please confirm to proceed further.`,
        buttons: {
            confirm: {
                label: 'CONFIRM',
                className: 'btn-primary',
            },
            cancel: {
                label: 'CANCEL',
                className: 'btn-primary',
            },
        },
        callback(result) {
            if (result) {
                clearExistingData();
                updateTeam(teamId);
                $('.team-selector').val(teamId).trigger('change');
                return;
            } else {
                const teamId = getTeamId();
                $('.team-selector').val(teamId).trigger('change');
            }
        },
    });
}

function updateTeam(teamId) {
    teamManager.setTeamId = teamId;
    const clientId = getClientId();
    populateCalendarView(clientId, teamId);
    const isAuditHistoryVisible = $('.audit-history-view').is(':visible');
    if (isAuditHistoryVisible) {
        renderAuditHistoryView();
    }
}

function clearExistingData() {
    $('#calendar').fullCalendar('removeEventSources');
    $('.data-field').empty();
}
