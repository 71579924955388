import { platformBaseUrl } from './common';
import { getApi } from './api-manager';

export const platformConfig = (function () {
    var instance;

    function init(config) {
        return {
            getIsAuth0Available() {
                return config.isAuth0Available;
            },

            getLoginFlowVersion() {
                return config.loginFlowVersion;
            },
        };
    }

    return {
        getPlatformConfig() {
            return instance;
        },
        updatePlatformConfig(config) {
            instance = init(config);
            return instance;
        },
    };
})();

export function fetchPlatformConfig(clientId) {
    const url = `${platformBaseUrl}/config`;
    const queryParam = {
        clientId,
    };
    return getApi(url, queryParam, true);
}
